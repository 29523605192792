'use client'
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'
interface DialogContextInterface {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  openDialog: (t: any) => void
  closeDialog: () => void
  fromAprio: boolean
  setFromAprio: React.Dispatch<React.SetStateAction<boolean>>
}

type LoaderTypes = boolean

const initialState = {} as DialogContextInterface

const DialogContext = createContext<DialogContextInterface>(initialState)

export const useDialogData = () => useContext(DialogContext)

const DialogContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [show, setShow] = useState<LoaderTypes>(false)
  const [fromAprio, setFromAprio] = useState<boolean>(false)

  const openDialog = useCallback(() => {
    setShow(true)
  }, [])
  const closeDialog = useCallback(() => {
    setShow(false)
    setFromAprio(false)
  }, [])

  const contextValue = useMemo(
    () => ({ show, setShow, openDialog, closeDialog, fromAprio, setFromAprio }),
    [show, setShow, openDialog, closeDialog, fromAprio, setFromAprio]
  )

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
    </DialogContext.Provider>
  )
}

export default DialogContextProvider
